import React, { useState, useEffect } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { BsEyeFill, BsFillPersonCheckFill, BsPlus } from "react-icons/bs";
import CardComponent from "../../components/CardComponent";
import Modaljs from "../../components/Modal";
import {
  approveRejectEarthingTestingById,
  changeStatusEarthingTesting,
} from "../../services/contractorApi";
import { toast } from "react-toastify";
import ConfirmAlert from "../../components/ConfirmAlert";
import ReactPagination from "../../components/ReactPagination";
import { getAllEarthingTesting } from "../../services/contractorApi";
import ActionButton from "../../components/ActionButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getAdminAllEnergy,
  PostAssignEarthingTesting,
  PostAssignSurvey,
} from "../../services/authapi";
import { Formik } from "formik";
import TooltipComponent from "../../components/TooltipComponent";
import { addSurveyAssignSchema } from "../../utils/formSchema";
import Select from "react-select";

const ApprovedEarthingTesting = () => {
  const [requireData, setRequireData] = useState([]);
  const [earthingTestingId, setEarthingTestingId] = useState("");
  const [showReject, setShowReject] = useState(false);
  const [edit, setEdit] = useState({});
  const [viewDetails, setViewDetails] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [pageDetail, setPageDetail] = useState({});
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [showRole, setShowRole] = useState();
  const [assign, setAssign] = useState("");
  const [allEnergy, setAllEnergy] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchAllAssetsRepairRequireData = async () => {
    const status = 2;
    const res = await getAllEarthingTesting({
      search,
      pageSize,
      pageNo,
      status,
    });
    if (res.status) {
      setRequireData(res.data);
      setPageDetail(res.pageDetails);
    } else {
      setRequireData([]);
      setPageDetail({});
    }
    setIsLoading(false);
  };

  const handleApproveReject = async () => {
    console.log(requireData, "require data");
    const status = "3";
    const res = await approveRejectEarthingTestingById(
      status,
      earthingTestingId
    );

    if (res.status) {
      toast.success(res.message);
      setRequireData((prev) =>
        prev.filter((itm) => itm.id !== earthingTestingId)
      );
      setPageDetail({
        ...pageDetail,
        total: +pageDetail.total - 1,
        pageEndResult: pageDetail.pageEndResult - 1,
      });
    } else {
      toast.error(res.message);
    }

    setEarthingTestingId("");
    setShowReject(false);
  };

  useEffect(() => {
    fetchAllAssetsRepairRequireData();
  }, [search, pageNo, pageSize]);

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
  };

  const serialNumber = Array.from(
    { length: pageDetail?.pageEndResult - pageDetail?.pageStartResult + 1 },
    (_, index) => pageDetail?.pageStartResult + index
  );

  const fetchAllEnergyData = async () => {
    const res = await getAdminAllEnergy();
    if (res.status) {
      setAllEnergy(res.data);
    } else {
      setAllEnergy([]);
    }
  };

  useEffect(() => {
    fetchAllEnergyData();
  }, [search, pageNo, pageSize]);

  const handleAssign = (id) => {
    setAssign(id);
    setShowRole(true);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const sData = {
      id: assign,
      assign_to: values.assign_to.value,
    };

    const res = await PostAssignEarthingTesting(sData);
    if (res.status) {
      fetchAllAssetsRepairRequireData();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    resetForm();
    setSubmitting(false);
    setShowRole(false);
  };
  return (
    <>
      <Col md={12}>
        <CardComponent
          showBackButton={true}
          title={"Approved Earthing Testing"}
          search={true}
          searchOnChange={(e) => {
            setSearch(e.target.value);
          }}
        >
          <div className="table-scroll">
            <Table className="text-body bg-new Roles">
              <thead className="text-truncate">
                <tr>
                  <th>{t("Sr No.")}</th>
                  <th>{t("Complaint id")}</th>
                  <th>{t("Complaint Type")}</th>
                  <th>{t("Outlet Data")}</th>
                  <th>{t("User Data")}</th>
                  <th>{t("Expiry Date")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <td colSpan={7}>
                    <img
                      className="p-3"
                      width="250"
                      src={`${process.env.REACT_APP_API_URL}/assets/images/Curve-Loading.gif`}
                      alt="Loading"
                    />
                  </td>
                ) : requireData.length > 0 ? (
                  <>
                    {requireData?.map((itm, idx) => (
                      <tr key={idx}>
                        <td>{serialNumber[idx]}</td>
                        <td>{itm?.complaint_unique_id}</td>
                        <td>{itm?.complaint_type_name}</td>
                        <td>
                          <span className="d-grid gap-2">
                            {itm?.outletData?.map((itm, i1) => (
                              <div key={i1} className="shadow px-1">
                                {i1 + 1}. {itm.outlet_name}
                              </div>
                            ))}
                          </span>
                        </td>
                        <td>
                          <span className="d-grid gap-2">
                            {itm?.user_data?.map((itm, i1) => (
                              <div key={i1} className="shadow px-1">
                                {i1 + 1}. {itm.name}
                              </div>
                            ))}
                          </span>
                        </td>
                        <td>{itm?.expire_date ?? "--"}</td>
                        <td>
                          <ActionButton
                            hideDelete={"d-none"}
                            eyeOnclick={() =>
                              navigate(`/earthing-testing/view`, {
                                state: {
                                  id: itm.id,
                                },
                              })
                            }
                            rejectOnclick={() => {
                              setEarthingTestingId(itm.id);
                              setShowReject(true);
                            }}
                            editlink={`/earthing-testing/create/${itm.id}`}
                            custom={
                              <TooltipComponent title={"Allocate"}>
                                <BsFillPersonCheckFill
                                  onClick={() => handleAssign(itm?.id)}
                                  className="social-btn red-combo"
                                />
                              </TooltipComponent>
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <td colSpan={7}>
                    <img
                      className="p-3"
                      alt="no-result"
                      width="250"
                      src={`${process.env.REACT_APP_API_URL}/assets/images/no-results.png`}
                    />
                  </td>
                )}
              </tbody>
              <tfoot>
                <td colSpan={12}>
                  <ReactPagination
                    pageSize={pageSize}
                    prevClassName={
                      pageNo === 1
                        ? "danger-combo-disable pe-none"
                        : "red-combo"
                    }
                    nextClassName={
                      pageSize == pageDetail?.total
                        ? requireData.length - 1 < pageSize
                          ? "danger-combo-disable pe-none"
                          : "success-combo"
                        : requireData.length < pageSize
                        ? "danger-combo-disable pe-none"
                        : "success-combo"
                    }
                    title={`Showing ${pageDetail?.pageStartResult || 0} to ${
                      pageDetail?.pageEndResult || 0
                    } of ${pageDetail?.total || 0}`}
                    handlePageSizeChange={handlePageSizeChange}
                    prevonClick={() => setPageNo(pageNo - 1)}
                    nextonClick={() => setPageNo(pageNo + 1)}
                  />
                </td>
              </tfoot>
            </Table>
          </div>
        </CardComponent>

        <ConfirmAlert
          size={"sm"}
          deleteFunction={handleApproveReject}
          hide={setShowReject}
          show={showReject}
          title={"Confirm reject"}
          description={"Are you sure you want to reject this!!"}
        />
      </Col>
      <Formik
        enableReinitialize={true}
        initialValues={{
          assign_to: "",
        }}
        validationSchema={addSurveyAssignSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Modaljs
            formikProps={props}
            open={showRole}
            size={"sm"}
            closebtn={"Cancel"}
            Savebtn={"Assign"}
            close={() => setShowRole(false)}
            title={"Create Assign"}
          >
            <Row className="g-2">
              <Form.Group as={Col} md="12">
                <Form.Label>Select Energy Company</Form.Label>
                <Select
                  menuPosition="fixed"
                  name={"assign_to"}
                  value={props.values.assign_to}
                  options={allEnergy.map((data) => ({
                    label: data?.name,
                    value: data?.user_id,
                  }))}
                  onChange={(selectedOption) => {
                    props.setFieldValue("assign_to", selectedOption);
                  }}
                  onBlur={props.handleBlur}
                  isInvalid={Boolean(
                    props.touched.assign_to && props.errors.assign_to
                  )}
                />
                <small className="text-danger">{props.errors.assign_to}</small>
              </Form.Group>
            </Row>
          </Modaljs>
        )}
      </Formik>
    </>
  );
};

export default ApprovedEarthingTesting;
