import React, { useState, useEffect } from "react";
import { Col, Table } from "react-bootstrap";
import CardComponent from "../../components/CardComponent";
import ReactPagination from "../../components/ReactPagination";
import { getAllEarthingTesting } from "../../services/contractorApi";
import ActionButton from "../../components/ActionButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const ReportEarthingTesting = () => {
  const [requireData, setRequireData] = useState([]);
  const [complaintData, setComplaintData] = useState([]);
  const [pageDetail, setPageDetail] = useState({});
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchAllAssetsRepairRequireData = async () => {
    const status = 5;
    const res = await getAllEarthingTesting({
      search,
      pageSize,
      pageNo,
      status,
    });
    if (res.status) {
      setRequireData(res.data);
      setPageDetail(res.pageDetails);
    } else {
      setRequireData([]);
      setPageDetail({});
    }
    setIsLoading(false);
  };
  const fetchAllComplaintData = async () => {
    const status = 5;
    const res = await getAllEarthingTesting({
      status,
      isDropdown: true,
    });
    if (res.status) {
      setComplaintData(res.data);
    } else {
      setComplaintData([]);
    }
  };

  useEffect(() => {
    fetchAllComplaintData();
  }, [search]);

  useEffect(() => {
    fetchAllAssetsRepairRequireData();
  }, [search, pageNo, pageSize]);

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
  };

  const serialNumber = Array.from(
    { length: pageDetail?.pageEndResult - pageDetail?.pageStartResult + 1 },
    (_, index) => pageDetail?.pageStartResult + index
  );

  return (
    <>
      <Col md={12}>
        <CardComponent
          showBackButton={true}
          title={"All Earthing Reports"}
          search={true}
          searchOnChange={(e) => {
            setSearch(e.target.value);
          }}
          custom={
            <Select
              placeholder={t("select complaint")}
              menuPortalTarget={document.body}
              options={complaintData?.map((data) => ({
                label: data?.complaint_unique_id,
                value: data?.complaint_unique_id,
              }))}
              onChange={(e) => {
                setSearch(e ? e.value : null);
              }}
              isClearable
            />
          }
        >
          <div className="table-scroll">
            <Table className="text-body bg-new Roles">
              <thead className="text-truncate">
                <tr>
                  <th>{t("Sr No.")}</th>
                  <th>{t("Complaint id")}</th>
                  <th>{t("Complaint Type")}</th>
                  <th>{t("Outlet Data")}</th>
                  <th>{t("User Data")}</th>
                  <th>{t("Expiry Date")}</th>
                  <th>{t("status")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <td colSpan={7}>
                    <img
                      className="p-3"
                      width="250"
                      src={`${process.env.REACT_APP_API_URL}/assets/images/Curve-Loading.gif`}
                      alt="Loading"
                    />
                  </td>
                ) : requireData.length > 0 ? (
                  <>
                    {requireData?.map((itm, idx) => (
                      <tr key={idx}>
                        <td>{serialNumber[idx]}</td>
                        <td>{itm?.complaint_unique_id || "-"}</td>
                        <td>{itm?.complaint_type_name || "-"}</td>
                        <td>
                          <span className="d-grid gap-2">
                            {itm?.outletData?.map((itm, i1) => (
                              <div key={i1} className="shadow px-1">
                                {i1 + 1}. {itm.outlet_name}
                              </div>
                            ))}
                          </span>
                        </td>
                        <td>
                          <span className="d-grid gap-2">
                            {itm?.user_data?.map((itm, i1) => (
                              <div key={i1} className="shadow px-1">
                                {i1 + 1}. {itm.name}
                              </div>
                            ))}
                          </span>
                        </td>
                        <td>{itm?.expire_date || "-"}</td>
                        <td
                          className={`${
                            itm.status == "1"
                              ? "text-orange"
                              : itm.status == "2"
                              ? "text-green"
                              : itm.status == "3"
                              ? "text-danger"
                              : "text-black"
                          }`}
                        >
                          {itm.status == "1"
                            ? "requested"
                            : itm.status == "2"
                            ? "approved"
                            : itm.status == "3"
                            ? "rejected"
                            : itm.status == "4"
                            ? "allocate"
                            : itm.status == "5"
                            ? "report"
                            : ""}
                        </td>
                        <td>
                          <ActionButton
                            hideDelete={"d-none"}
                            hideEdit={"d-none"}
                            eyeOnclick={() =>
                              navigate(`/earthing-testing/view`, {
                                state: {
                                  id: itm.id,
                                },
                              })
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <td colSpan={7}>
                    <img
                      className="p-3"
                      alt="no-result"
                      width="250"
                      src={`${process.env.REACT_APP_API_URL}/assets/images/no-results.png`}
                    />
                  </td>
                )}
              </tbody>
              <tfoot>
                <td colSpan={12}>
                  <ReactPagination
                    pageSize={pageSize}
                    prevClassName={
                      pageNo === 1
                        ? "danger-combo-disable pe-none"
                        : "red-combo"
                    }
                    nextClassName={
                      pageSize == pageDetail?.total
                        ? requireData.length - 1 < pageSize
                          ? "danger-combo-disable pe-none"
                          : "success-combo"
                        : requireData.length < pageSize
                        ? "danger-combo-disable pe-none"
                        : "success-combo"
                    }
                    title={`Showing ${pageDetail?.pageStartResult || 0} to ${
                      pageDetail?.pageEndResult || 0
                    } of ${pageDetail?.total || 0}`}
                    handlePageSizeChange={handlePageSizeChange}
                    prevonClick={() => setPageNo(pageNo - 1)}
                    nextonClick={() => setPageNo(pageNo + 1)}
                  />
                </td>
              </tfoot>
            </Table>
          </div>
        </CardComponent>
      </Col>
    </>
  );
};

export default ReportEarthingTesting;
