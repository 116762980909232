import React, { useEffect, useState } from "react";
import { Col, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { BsCheckLg, BsEyeFill, BsXLg } from "react-icons/bs";
import { toast } from "react-toastify";
import moment from "moment";
import {
  PostApprovedRejectSurvey,
  getAllRequestedSurvey,
} from "../../services/authapi";
import CardComponent from "../../components/CardComponent";
import TooltipComponent from "../../components/TooltipComponent";
import { Link } from "react-router-dom";
import ReactPagination from "../../components/ReactPagination";
import ActionButton from "../../components/ActionButton";
import ConfirmAlert from "../../components/ConfirmAlert";

const RequestSurvey = () => {
  const [survey, setSurvey] = useState([]);
  const [pageDetail, setPageDetail] = useState({});
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [approveData, setApproveData] = useState("");
  const [rejectData, setRejectData] = useState("");

  const fetchAllSurveyData = async () => {
    const status = 1;
    const res = await getAllRequestedSurvey(search, pageSize, pageNo, status);

    if (res.status) {
      setSurvey(res.data);
      setPageDetail(res.pageDetails);
    } else {
      setSurvey([]);
      setPageDetail({});
    }
  };

  const handleApproved = async () => {
    const sData = {
      survey_id: approveData.survey_id,
      status: 2,
    };
    const res = await PostApprovedRejectSurvey(sData);
    if (res.status) {
      toast.success(res.message);
      setSurvey((prev) =>
        prev.filter((itm) => itm.survey_id !== +approveData.survey_id)
      );
    } else {
      toast.error(res.message);
    }
    setApproveData("");
    setShowApprove(false);
  };

  const handleRejected = async () => {
    const sData = {
      survey_id: rejectData.survey_id,
      status: 3,
    };
    const res = await PostApprovedRejectSurvey(sData);
    if (res.status) {
      toast.success(res.message);
      setSurvey((prev) =>
        prev.filter((itm) => itm.survey_id !== +rejectData.survey_id)
      );
    } else {
      toast.error(res.message);
    }
    setRejectData("");
    setShowReject(false);
  };

  useEffect(() => {
    fetchAllSurveyData();
  }, [search, pageSize, pageNo]);

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
  };

  const serialNumber = Array.from(
    { length: pageDetail?.pageEndResult - pageDetail?.pageStartResult + 1 },
    (_, index) => pageDetail?.pageStartResult + index
  );

  return (
    <>
      <Helmet>
        <title>All Survey · CMS Electricals</title>
      </Helmet>
      <Col md={12} data-aos={"fade-up"} data-aos-delay={200}>
        <CardComponent
          showBackButton={true}
          title={"Request Survey"}
          search={true}
          searchOnChange={(e) => {
            setSearch(e.target.value);
          }}
        >
          <div className="table-scroll">
            <Table className="text-body bg-new Roles">
              <thead className="text-truncate">
                <tr>
                  {["Sr No.", "Survey", "Date", "Action"].map((thead) => (
                    <th key={thead}>{thead}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {survey?.length > 0 ? null : (
                  <tr>
                    <td colSpan={7}>
                      <img
                        className="p-3"
                        alt="no-result"
                        width="250"
                        src={`${process.env.REACT_APP_API_URL}/assets/images/no-results.png`}
                      />
                    </td>
                  </tr>
                )}
                {survey?.map((data, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>

                    <td>{data?.title}</td>
                    <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>

                    <td>
                      <ActionButton
                        approveOnclick={() => {
                          setApproveData(data);
                          setShowApprove(true);
                        }}
                        rejectOnclick={() => {
                          setRejectData(data);
                          setShowReject(true);
                        }}
                        hideEye={"d-none"}
                        hideEdit={"d-none"}
                        hideDelete={"d-none"}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={10}>
                    <ConfirmAlert
                      size={"sm"}
                      deleteFunction={handleApproved}
                      hide={setShowApprove}
                      show={showApprove}
                      title={"Confirm Approve"}
                      description={"Are you sure you want to approve this!!"}
                    />

                    <ConfirmAlert
                      size={"sm"}
                      deleteFunction={handleRejected}
                      hide={setShowReject}
                      show={showReject}
                      title={"Confirm reject"}
                      description={"Are you sure you want to reject this!!"}
                    />
                    <ReactPagination
                      pageSize={pageSize}
                      prevClassName={
                        pageNo === 1
                          ? "danger-combo-disable pe-none"
                          : "red-combo"
                      }
                      nextClassName={
                        pageSize == pageDetail?.total
                          ? survey.length - 1 < pageSize
                            ? "danger-combo-disable pe-none"
                            : "success-combo"
                          : survey.length < pageSize
                          ? "danger-combo-disable pe-none"
                          : "success-combo"
                      }
                      title={`Showing ${pageDetail?.pageStartResult || 0} to ${
                        pageDetail?.pageEndResult || 0
                      } of ${pageDetail?.total || 0}`}
                      handlePageSizeChange={handlePageSizeChange}
                      prevonClick={() => setPageNo(pageNo - 1)}
                      nextonClick={() => setPageNo(pageNo + 1)}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </CardComponent>
      </Col>
    </>
  );
};

export default RequestSurvey;
