import moment from "moment";

// Check if the current date is within the financial year
export const isCurrentFinancialYear = (item) => {
  const currentDate = new Date();
  return (
    currentDate >= new Date(item?.start_date) &&
    currentDate <= new Date(item?.end_date)
  );
};

export const getDateValue = (val) => {
  return val ? moment(val).format("DD-MM-YYYY") : "-";
};

export const QUERY_PARAMS = (params) =>
  Object.entries(params)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== ""
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

export const replaceSpaces = (val) => {
  return val
    ? val
        .toLowerCase()
        .replace(/%20| /g, "_")
        .replace(/[^a-z0-9_]/g, "")
    : "";
};

export const findMatchingPath = (route, currentPath) => {
  if (route?.length > 0) {
    for (let i = 0; i < route?.length; i++) {
      if (route[i].path === currentPath) {
        return route[i];
      }
      if (route[i].submodules?.length > 0) {
        const submoduleResult = findMatchingPath(
          route[i].submodules,
          currentPath
        );
        if (submoduleResult) {
          return submoduleResult;
        }
        for (let j = 0; j < route[i].modulesOfSubModule; i++) {
          if (route[i].modulesOfSubModule[j].path === currentPath) {
            return route[i].modulesOfSubmodule[j];
          }
        }
      }
    }
  }

  return null;
};
