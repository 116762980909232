import React from 'react'

const AssignedTask = () => {
  return (
    <div>
      AssignedTask
    </div>
  )
}

export default AssignedTask
