import React from 'react'

const RejectedTask = () => {
  return (
    <div>
      RejectedTask
    </div>
  )
}

export default RejectedTask
