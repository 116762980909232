import React from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { X } from "lucide-react";

const DateRange = ({ value, setValue, ...props }) => {
  const handleApply = (e, picker) => {
    setValue(
      `${moment(picker.startDate).format("YYYY-MM-DD")},${moment(
        picker.endDate
      ).format("YYYY-MM-DD")}`
    );
  };

  const handleClear = () => {
    setValue("");
  };

  return (
    <>
      <DateRangePicker
        initialSettings={{ startDate: new Date(), endDate: new Date() }}
        onApply={handleApply}
      >
        <input type="text" value={value} className="form-control" {...props} />
      </DateRangePicker>
    </>
  );
};

export default DateRange;
