import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import Select from "react-select";
import {
  getAllAccountByBankId,
  getAllBankListForDropdown,
} from "../../services/contractorApi";
import { getAllAccountTransactionsByBank } from "../../services/contractoApi2";
import ReactPagination from "../../components/ReactPagination";
import "react-calendar/dist/Calendar.css";
import FundTransactionEmployee from "../FundManagement/FundTransactionEmployee";
import StockTransactionSupplier from "./StockTransactionsSupplier";
import { useTranslation } from "react-i18next";
import MultiSelectVisibility from "../Complaints/MultiSelectVisibility";
import { toast } from "react-toastify";
import DateRange from "../../components/DateRange";
import { FREQUENCY } from "../../data/StaticData";
import { getDateValue } from "../../utils/helper";
import FormLabelText from "../../components/FormLabelText";
import ExportExcelPdf from "../../components/ExportExcelPdf";
import { UserDetail } from "../../components/ItemDetail";

const ViewStockTransactions = () => {
  const [allAccounts, setallAccounts] = useState([]);
  const [bankId, setBankId] = useState("");
  const [pageDetail, setPageDetail] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [allBanksData, setAllBanksData] = useState([]);
  const [allAccountByBankId, setAllAccountByBankId] = useState([]);
  const [accountDetails, setAccountDetails] = useState("");
  const [dateRangeValue, setDateRangeValue] = useState("");
  const [filterBy, setFilterby] = useState("");
  const { t } = useTranslation();

  const [BalanceFor, setBalanceFor] = useState("bank");
  useEffect(() => {
    fetchAllBanksData();
  }, []);

  useEffect(() => {
    handleAccountByBankIdChange();
  }, [bankId, filterBy, dateRangeValue, pageSize, pageNo, searchTerm]);

  const fetchAllBanksData = async () => {
    const res = await getAllBankListForDropdown();
    if (res.status) {
      const rData = res.data.map((itm) => {
        return {
          value: itm.id,
          label: itm.bank_name,
          logo: itm.logo,
        };
      });
      setAllBanksData(rData);
    } else {
      setAllBanksData([]);
    }
  };

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
  };

  const userFormatOptionLabel = ({ label, logo }) => (
    <div>
      {logo ? (
        <img
          src={process.env.REACT_APP_API_URL + logo}
          className="avatar me-2"
        />
      ) : null}
      {label}
    </div>
  );

  const getAllBankAccountByBankId = (id) => {
    if (!id) return false;
    fetchAllAccountByBankId(id);
  };

  const fetchAllAccountByBankId = async (id) => {
    const res = await getAllAccountByBankId(id);
    if (res.status) {
      setAllAccountByBankId(res?.data);
    } else {
      setAllAccountByBankId([]);
    }
  };

  const handleAccountByBankIdChange = async () => {
    const typeselect = "stock";
    const id = accountDetails?.value;
    setLoading(true);
    const res = await getAllAccountTransactionsByBank(id, typeselect, {
      date: filterBy || dateRangeValue,
      pageSize,
      pageNo,
      search: searchTerm,
    });
    if (res.data) {
      setallAccounts(res.data);
      setPageDetail(res.pageDetails);
    } else {
      setallAccounts([]);
      setPageDetail({});
    }
    setLoading(false);
  };

  const headerNames = [
    { name: "Account No.", value: "account_number" },
    { name: "Account Type", value: "account_type" },
    { name: "IFSC code", value: "ifsc_code" },
    { name: "Branch", value: "branch" },
    { name: "Transaction", value: "transaction" },
    { name: "Status", value: "status" },
    { name: "Description", value: "description" },
    { name: "Transaction Id", value: "transaction_id" },
  ];

  return (
    <div>
      <Col md={12} data-aos={"fade-up"}>
        <Card className="card-bg">
          <h6 className=" fw-bold mx-3 my-3">{t("View Stock Transactions")}</h6>

          <Form.Group as={Col} md={12}>
            <Stack
              className={`text-truncate px-0 after-bg-light social-btn-re w-auto h-auto `}
              direction="horizontal"
              gap={4}
            >
              <span className="ps-3">{t("Transactions Overview For")} : </span>
              <label className="fw-bolder">
                <input
                  type="radio"
                  name="fund_request_for"
                  value={"1"}
                  onChange={() => {
                    setBalanceFor("bank");
                    setAccountDetails("");
                  }}
                  defaultChecked
                  className="form-check-input"
                />
                {t("Bank")}
              </label>
              <div className={`vr hr-shadow`} />
              <label className="fw-bolder">
                <input
                  type="radio"
                  name="fund_request_for"
                  value={"2"}
                  onChange={() => {
                    setBalanceFor("employee");
                    setAccountDetails("");
                  }}
                  className="form-check-input"
                />
                {t("Employee")}
              </label>
              <div className={`vr hr-shadow`} />

              <label className="fw-bolder">
                <input
                  type="radio"
                  name="fund_request_for"
                  value={"2"}
                  onChange={() => {
                    setBalanceFor("Supplier");
                    setAccountDetails("");
                  }}
                  className="form-check-input"
                />
                {t("Supplier")}
              </label>
            </Stack>
          </Form.Group>

          {BalanceFor == t("bank") ? (
            <>
              <div className="p-3">
                <Row>
                  <Col md={8}>
                    <Row className="g-2 align-items-end">
                      <Col md={6}>
                        <FormLabelText children={t("Select Bank")} />
                        <Select
                          menuPortalTarget={document.body}
                          options={allBanksData}
                          onChange={(e) => {
                            getAllBankAccountByBankId(e.value);
                            setBankId("");
                            setAccountDetails("");
                          }}
                          formatOptionLabel={userFormatOptionLabel}
                        />
                      </Col>
                      <Col md={6}>
                        <FormLabelText children={t("Select Account")} />
                        <Select
                          menuPortalTarget={document.body}
                          options={allAccountByBankId?.map((data) => ({
                            label: data?.account_number || "-",
                            value: data?.id,
                            account_holder_name: data?.account_holder_name,
                            account_type: data?.account_type,
                            logo: data.res?.data?.bank_logo,
                            balance: data.balance,
                          }))}
                          onChange={(e) => {
                            setBankId(e.value);
                            setAccountDetails(e);
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <FormLabelText children={t("Select Date Range")} />
                        <DateRange
                          value={dateRangeValue}
                          setValue={setDateRangeValue}
                        />
                      </Col>
                      <Col md={6}>
                        <FormLabelText children={t("Select Frequency")} />
                        <Select
                          menuPortalTarget={document.body}
                          options={FREQUENCY}
                          isClearable
                          onChange={(e) => {
                            setFilterby(e ? e.value : null);
                          }}
                        />
                      </Col>
                      <ExportExcelPdf
                        id={accountDetails?.value}
                        typeSelect={"stock"}
                        api={getAllAccountTransactionsByBank}
                        headerNames={headerNames}
                      />
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col md={12}>
                        <span className="position-relative">
                          {" "}
                          {t("Account Holder Name")} :{" "}
                          <span className="fw-bold">
                            {accountDetails?.account_holder_name}
                          </span>
                          <br />
                          {t("Account Number")} :{" "}
                          <span className="fw-bold">
                            {accountDetails?.label}
                          </span>
                          <br />
                          {t("Account Balance")} :{" "}
                          <span className="fw-bold text-green">
                            {accountDetails?.balance}
                          </span>
                        </span>
                      </Col>
                      <Col md={12}>
                        <div className="position-relative mt-3">
                          <BsSearch className="position-absolute top-50 me-3 end-0 translate-middle-y" />
                          <Form.Control
                            type="text"
                            placeholder={t("Search")}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="me-2"
                            aria-label="Search"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="mt-3 table-scroll">
                  <Table className="text-body bg-new Roles">
                    <thead className="text-truncate">
                      <tr>
                        <th>{t("Sr No.")}</th>
                        <th>{t("Account No.")}</th>
                        <th>{t("Account Type")}</th>
                        <th>{t("IFSC code")}</th>
                        <th>{t("Branch")}</th>
                        <th>{t("User")}</th>
                        <th>{t("Transaction")}</th>
                        <th>{t("Status")}</th>
                        <th>{t("Description")}</th>
                        <th>{t("Date")}</th>
                        <th>{t("Transaction Id")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={15}>
                            <img
                              className="p-3"
                              width="250"
                              src={`${process.env.REACT_APP_API_URL}/assets/images/Curve-Loading.gif`}
                              alt={t("Loading")}
                            />
                          </td>
                        </tr>
                      ) : allAccounts?.length > 0 ? (
                        allAccounts?.map((data, id1) => (
                          <tr key={id1}>
                            <td>{id1 + 1}</td>
                            <td>{data?.account_number}</td>
                            <td>{data?.account_type}</td>
                            <td>{data?.ifsc_code}</td>
                            <td>{data?.branch}</td>
                            <td>
                              <UserDetail
                                img={data?.image}
                                name={data?.username}
                                id={data?.user_id}
                                unique_id={data?.employee_id}
                              />
                            </td>
                            <td>{data?.transaction}</td>
                            <td
                              className={`text-${
                                data?.status == "debit" ? "danger" : "green"
                              }`}
                            >
                              {data?.status}
                            </td>
                            <td>{data?.description}</td>
                            <td>{getDateValue(data?.date)}</td>
                            <td>{data?.transaction_id}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={15}>
                            <img
                              className="p-3"
                              alt="no-result"
                              width="250"
                              src={`${process.env.REACT_APP_API_URL}/assets/images/no-results.png`}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={15}>
                          <ReactPagination
                            pageSize={pageSize}
                            prevClassName={
                              pageNo === 1
                                ? "danger-combo-disable pe-none"
                                : "red-combo"
                            }
                            nextClassName={
                              pageSize == pageDetail?.total
                                ? allAccounts.length - 1 < pageSize
                                  ? "danger-combo-disable pe-none"
                                  : "success-combo"
                                : allAccounts.length < pageSize
                                ? "danger-combo-disable pe-none"
                                : "success-combo"
                            }
                            title={`Showing ${
                              pageDetail?.pageStartResult || 0
                            } to ${pageDetail?.pageEndResult || 0} of ${
                              pageDetail?.total || 0
                            }`}
                            handlePageSizeChange={handlePageSizeChange}
                            prevonClick={() => setPageNo(pageNo - 1)}
                            nextonClick={() => setPageNo(pageNo + 1)}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>
            </>
          ) : BalanceFor == "Supplier" ? (
            <StockTransactionSupplier />
          ) : (
            <FundTransactionEmployee />
          )}
        </Card>
      </Col>
    </div>
  );
};

export default ViewStockTransactions;
