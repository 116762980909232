import React from "react";
import { useState } from "react";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import { Card, Col, Table } from "react-bootstrap";
import ConfirmAlert from "../../components/ConfirmAlert";
import ReactPagination from "../../components/ReactPagination";
import {
  deletePurchaseOrderById,
  getAllPurchaseOrder,
} from "../../services/contractorApi";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ActionButton from "../../components/ActionButton";
import { useTranslation } from "react-i18next";
import { ItemDetail, UserDetail } from "../../components/ItemDetail";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../components/SearchComponent";
import {
  approveRejectFundtemById,
  getAdminAllSurveyItemMaster,
} from "../../services/authapi";
import StatusChip from "../../components/StatusChip";

const ApprovedFundItem = () => {
  const [fundItemData, setFundItemData] = useState([]);
  const [pageDetail, setPageDetail] = useState({});
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [showReject, setShowReject] = useState(false);
  const [fundItemId, setFundItemId] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const fetchFundItemsData = async () => {
    const status = 1;
    const category = "fund";
    const res = await getAdminAllSurveyItemMaster({
      search,
      pageSize,
      pageNo,
      status,
      category,
    });
    if (res.status) {
      setFundItemData(res.data);
      setPageDetail(res.pageDetails);
    } else {
      setFundItemData([]);
      setPageDetail({});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFundItemsData();
  }, [search, pageNo, pageSize]);

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
  };

  const serialNumber = Array.from(
    { length: pageDetail?.pageEndResult - pageDetail?.pageStartResult + 1 },
    (_, index) => pageDetail?.pageStartResult + index
  );

  const handleApproveReject = async () => {
    const status = "2";
    const category = "fund";
    const res = await approveRejectFundtemById({
      id: fundItemId,
      status,
      category,
    });
    if (res.status) {
      toast.success(res.message);
      setFundItemData((prev) => prev.filter((itm) => itm.id !== fundItemId));
      setPageDetail({
        ...pageDetail,
        total: +pageDetail.total - 1,
        pageEndResult: pageDetail.pageEndResult - 1,
      });
    } else {
      toast.error(res.message);
    }
    setFundItemId("");
    setShowReject(false);
  };
  return (
    <>
      <Helmet>
        <title>Fund Item · CMS Electricals</title>
      </Helmet>
      <Col md={12} data-aos={"fade-up"}>
        <div className="overflow-auto p-3 mb-2">
          <div className="d-flex justify-content-end mb-3">
            <SearchComponent setSearch={setSearch} />
          </div>
          <Table className="text-body bg-new Roles">
            <thead className="text-truncate">
              <tr>
                <th>{t("Sr No.")}</th>
                <th>{t("Item")}</th>
                <th>{t("Qty")}</th>
                <th>{t("hsn code")}</th>
                <th>{t("supplier name")}</th>
                <th>{t("sub category")}</th>
                <th>{t("unit")}</th>
                <th>{t("status")}</th>
                <th>{t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={10}>
                    <img
                      className="p-3"
                      width="250"
                      src={`${process.env.REACT_APP_API_URL}/assets/images/Curve-Loading.gif`}
                      alt={t("Loading")}
                    />
                  </td>
                </tr>
              ) : fundItemData.length > 0 ? (
                <>
                  {fundItemData.map((data, id1) => (
                    <tr key={id1}>
                      <td>{serialNumber[id1]}</td>
                      <td>
                        <ItemDetail
                          img={data.image}
                          name={data.name}
                          unique_id={data.unique_id}
                        />
                      </td>
                      <td>{data.qty || "-"}</td>
                      <td>{data.hsncode || "-"}</td>
                      <td>
                        <UserDetail
                          img={data?.supplier_image}
                          name={data.supplier_name}
                          id={data.supplier_id}
                          unique_id={data.supplier_id}
                        />
                      </td>
                      <td>{data.sub_category || "-"}</td>
                      <td>{data.unit_name || "-"}</td>
                      <td>
                        <StatusChip status="Approved" />
                      </td>
                      <td>
                        <ActionButton
                          eyeOnclick={() =>
                            navigate(`/ItemMaster/view`, {
                              state: {
                                id: data.id,
                              },
                            })
                          }
                          rejectOnclick={() => {
                            setFundItemId(data.id);
                            setShowReject(true);
                          }}
                          hideDelete={false}
                          hideEdit={false}
                          approveMargin="mx-0"
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10}>
                    <img
                      className="p-3"
                      alt="no-result"
                      width="250"
                      src={`${process.env.REACT_APP_API_URL}/assets/images/no-results.png`}
                    />
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <td colSpan={10}>
                <ConfirmAlert
                  size={"sm"}
                  deleteFunction={handleApproveReject}
                  hide={setShowReject}
                  show={showReject}
                  title={"Confirm reject"}
                  description={"Are you sure you want to reject this!!"}
                />
                <ReactPagination
                  pageSize={pageSize}
                  prevClassName={
                    pageNo === 1 ? "danger-combo-disable pe-none" : "red-combo"
                  }
                  nextClassName={
                    pageSize == pageDetail?.total
                      ? fundItemData.length - 1 < pageSize
                        ? "danger-combo-disable pe-none"
                        : "success-combo"
                      : fundItemData.length < pageSize
                      ? "danger-combo-disable pe-none"
                      : "success-combo"
                  }
                  title={`Showing ${pageDetail?.pageStartResult || 0} to ${
                    pageDetail?.pageEndResult || 0
                  } of ${pageDetail?.total || 0}`}
                  handlePageSizeChange={handlePageSizeChange}
                  prevonClick={() => setPageNo(pageNo - 1)}
                  nextonClick={() => setPageNo(pageNo + 1)}
                />
              </td>
            </tfoot>
          </Table>
        </div>
      </Col>
    </>
  );
};

export default ApprovedFundItem;
