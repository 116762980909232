import React from 'react'

const ApprovedTask = () => {
  return (
    <div>
      ApprovedTask
    </div>
  )
}

export default ApprovedTask
