import React from "react";

const StatusChip = ({ status }) => {
  const commonGreenStyle = {
    color: "var(--text-green)",
  };
  const commonRedStyle = {
    color: "var(--bs-danger)",
  };
  const commonOrangeStyle = {
    color: "var(--btn-danger1)",
  };
  const commonSecondaryStyle = {
    color: "var(--bs-secondary)",
  };

  const colorCode = {
    Approved: commonGreenStyle,
    approved: commonGreenStyle,
    "Payment received": commonGreenStyle,
    Complete: commonGreenStyle,
    Completed: commonGreenStyle,
    Rejected: commonRedStyle,
    rejected: commonRedStyle,
    "Over-Due": commonRedStyle,
    "To-Do": commonOrangeStyle,
    Resolved: commonOrangeStyle,
    resolved: commonOrangeStyle,
    working: commonOrangeStyle,
    Progress: commonOrangeStyle,
    "In-Progress": commonGreenStyle,
    "Ready to PI": commonGreenStyle,
    hold: commonSecondaryStyle,
  };

  const { color } = colorCode[status] || "";

  return (
    <span style={{ color: color || "#8f8f0a" }} className="fw-bold">
      {status}
    </span>
  );
};

export default StatusChip;
