import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import ReactPagination from "../../components/ReactPagination";
import {
  getEmployeeList,
  getFundTransactionsOfEmployee,
} from "../../services/contractoApi2";
import {
  getAllEndUserBySupervisorId,
  getAllManagersUser,
  getAllOfficeUser,
  getSupervisorListWithTotalFreeUserByManagerId,
} from "../../services/contractorApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FormLabelText from "../../components/FormLabelText";
import ExportExcelPdf from "../../components/ExportExcelPdf";

export default function FundTransactionEmployee() {
  const [allAccounts, setallAccounts] = useState([]);
  const [pageDetail, setPageDetail] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [employeeType, setEmployeeType] = useState("manager");
  const [employeeId, setEmployeeId] = useState("");
  const [allOfficeUser, setAllofficeUser] = useState([]);
  const [allManagers, setAllManagers] = useState([]);
  const [freeSupervisorData, setFreeSupervisorData] = useState([]);
  const [freeUserData, setFreeUserData] = useState([]);
  const [selected, setSelected] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    fetchEmployeeList();
    fetchOfficeUser();
    fetchManagers();
  }, [employeeType]);

  useEffect(() => {
    getAccountBalance();
  }, [employeeId, pageSize, pageNo, searchTerm]);

  const fetchEmployeeList = async () => {
    const res = await getEmployeeList(employeeType);
    if (res?.status) {
      const rData = res.data.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
          logo: itm.image,
        };
      });
      setEmployeeList(rData);
    } else {
      setEmployeeList([]);
    }
  };

  const handleManagerChange = async (value, setvalue) => {
    if (!value) return setFreeSupervisorData([]);
    fetchFreeSupervisorData(value);
  };

  const handleSupervisorChange = async (value, setvalue) => {
    if (!value) return setFreeUserData([]);
    fetchFreeUsersData(value);
  };

  const fetchFreeUsersData = async (id) => {
    const res = await getAllEndUserBySupervisorId(id);
    if (res.status) {
      setFreeUserData(res.data);
    } else {
      setFreeUserData([]);
      toast.error(res.message);
    }
  };

  //All Supervisors By Manager Id
  const fetchFreeSupervisorData = async (id) => {
    const res = await getSupervisorListWithTotalFreeUserByManagerId(id);
    if (res.status) {
      setFreeSupervisorData(res.data);
    } else {
      setFreeSupervisorData([]);
      toast.error(res.message);
    }
  };

  const fetchManagers = async () => {
    const res = await getAllManagersUser();
    if (res.status) {
      setAllManagers(res.data);
    } else {
      setAllManagers([]);
    }
  };

  const fetchOfficeUser = async () => {
    const res = await getAllOfficeUser();
    if (res.status) {
      setAllofficeUser(res.data);
    } else {
      setAllofficeUser([]);
    }
  };

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
  };

  const userFormatOptionLabel = ({ label, logo }) => (
    <div>
      {/* {logo ? (
        <img
          src={process.env.REACT_APP_API_URL + logo}
          className="avatar me-2"
        />
      ) : null} */}
      {label}
    </div>
  );

  const getAccountBalance = async () => {
    setLoading(true);
    const res = await getFundTransactionsOfEmployee(employeeId, {
      pageSize,
      pageNo,
      search: searchTerm,
    });
    if (res.data) {
      setallAccounts(res.data);
      setPageDetail(res.pageDetails);
    } else {
      setallAccounts([]);
      setPageDetail({});
    }
    setLoading(false);
  };

  const headerNames = [
    { name: "Name", value: "username" },
    { name: "Employee Id", value: "employee_id" },
    { name: "Role", value: "role_name" },
    { name: "Amount", value: "amount" },
    { name: "Balance", value: "balance" },
    { name: "Transaction Type", value: "transaction_type" },
    { name: "Status", value: "status" },
    { name: "Transaction Date", value: "transaction_date" },
  ];

  return (
    <div>
      <>
        <div className="container mt-2">
          <Row className="g-2 align-items-end">
            <Form.Group as={Col} md={3}>
              <FormLabelText children={t("Office")} />
              <Select
                menuPortalTarget={document.body}
                options={allOfficeUser?.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                isDisabled={selected == "manager"}
                isClearable
                onChange={(e) => {
                  setEmployeeId(e?.value);
                  if (e) setSelected("office");
                  else setSelected("");
                }}
              />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <FormLabelText children={t("Manager")} />
              <Select
                menuPortalTarget={document.body}
                options={allManagers?.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                isDisabled={selected == "office"}
                isClearable
                onChange={(e) => {
                  handleManagerChange(e?.value);
                  setEmployeeId(e?.value);
                  if (e) setSelected("manager");
                  else setSelected("");
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <FormLabelText children={t("Supervisor")} />
              <Select
                menuPortalTarget={document.body}
                options={freeSupervisorData?.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                isClearable
                onChange={(e) => {
                  handleSupervisorChange(e?.value);
                  setEmployeeId(e?.value);
                }}
              />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <FormLabelText children={t("End User")} />
              <Select
                menuPortalTarget={document.body}
                options={freeUserData?.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                isClearable
                onChange={(e) => {
                  setEmployeeId(e?.value);
                }}
              />
            </Form.Group>
            <ExportExcelPdf
              employeeId={employeeId}
              api={getFundTransactionsOfEmployee}
              headerNames={headerNames}
            />
          </Row>
        </div>

        <div className="p-3">
          <div className="table-scroll">
            <Table className="text-body bg-new Roles">
              <thead className="text-truncate">
                <tr>
                  <th>{t("Sr No.")}</th>
                  <th>{t("Name")}</th>
                  <th>{t("Employee Id")}</th>
                  <th>{t("Role")}</th>
                  <th>{t("Amount")}</th>
                  <th>{t("Balance")}</th>
                  <th>{t("Transaction Type")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Transaction Date")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={15}>
                      <img
                        className="p-3"
                        width="250"
                        src={`${process.env.REACT_APP_API_URL}/assets/images/Curve-Loading.gif`}
                        alt="Loading"
                      />
                    </td>
                  </tr>
                ) : allAccounts?.length > 0 ? (
                  allAccounts?.map((data, id1) => (
                    <tr key={id1}>
                      <td>{id1 + 1}</td>
                      <td>{data?.username}</td>
                      <td>{data?.employee_id ?? "--"}</td>
                      <td>{data?.role_name}</td>
                      <td
                        className={`text-${
                          data?.transaction_type == "debit" ? "danger" : "green"
                        }`}
                      >
                        {data?.amount ?? "--"}
                      </td>
                      <td>{data?.balance}</td>
                      <td
                        className={`text-${
                          data?.transaction_type == "debit" ? "danger" : "green"
                        }`}
                      >
                        {data?.transaction_type}
                      </td>
                      <td>{data?.status}</td>
                      <td>{data?.transaction_date}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={15}>
                      <img
                        className="p-3"
                        alt="no-result"
                        width="250"
                        src={`${process.env.REACT_APP_API_URL}/assets/images/no-results.png`}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <td colSpan={15}>
                  <ReactPagination
                    pageSize={pageSize}
                    prevClassName={
                      pageNo === 1
                        ? "danger-combo-disable pe-none"
                        : "red-combo"
                    }
                    nextClassName={
                      pageSize == pageDetail?.total
                        ? allAccounts.length - 1 < pageSize
                          ? "danger-combo-disable pe-none"
                          : "success-combo"
                        : allAccounts.length < pageSize
                        ? "danger-combo-disable pe-none"
                        : "success-combo"
                    }
                    title={`Showing ${pageDetail?.pageStartResult || 0} to ${
                      pageDetail?.pageEndResult || 0
                    } of ${pageDetail?.total || 0}`}
                    handlePageSizeChange={handlePageSizeChange}
                    prevonClick={() => setPageNo(pageNo - 1)}
                    nextonClick={() => setPageNo(pageNo + 1)}
                  />
                </td>
              </tfoot>
            </Table>
          </div>
        </div>
      </>
    </div>
  );
}
