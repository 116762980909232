export const HEADER_TITLE_DEFAULT = "CMS Electricals";

export const FREQUENCY = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "This Week", value: "thisWeek" },
  { label: "Last Week", value: "lastWeek" },
  { label: "This Month", value: "thisMonth" },
  { label: "Last Month", value: "lastMonth" },
  { label: "This Quarter", value: "thisQuarter" },
  { label: "Last Quarter", value: "lastQuarter" },
  { label: "Last 6 Months", value: "last6Months" },
  { label: "Last 12 Months", value: "last12Months" },
];
export const GST_TREATMENT_TYPE = [
  {
    label: "Registered Business – Regular",
    value: "Registered Business – Regular",
  },
  {
    label: "Registered Business – Composition",
    value: "Registered Business – Composition",
  },
  {
    label: "Unregistered Business",
    value: "Unregistered Business",
  },
  { label: "Consumer", value: "Consumer" },
];
